var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content"},[_c('article',{staticClass:"row"},[_c('section',{staticClass:"col-lg-8 col-sm-12"},[_c('div',{staticClass:"centered text-center",staticStyle:{"width":"100%"},style:({
            background: ("linear-gradient(" + (this.secondaryColor) + ", black)"),
          })},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(
              _vm.mainImage +
              '?w=315&h=200&fit=clamp&auto=format'
            ),expression:"\n              mainImage +\n              '?w=315&h=200&fit=clamp&auto=format'\n            "}],staticClass:"d-inline-xs d-sm-none",staticStyle:{"margin-top":"-10px","z-index":"12","box-shadow":"0px 10px 15px 0px rgb(0 0 0 / 60%)","border-radius":"0"},attrs:{"width":"315px","height":"200px","alt":"tulum epic nights"}}),_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(
               _vm.mainImage +
              '?w=615&h=250&fit=clamp&auto=format'
            ),expression:"\n               mainImage +\n              '?w=615&h=250&fit=clamp&auto=format'\n            "}],staticClass:"d-none d-sm-inline",staticStyle:{"margin-top":"-10px","z-index":"12","box-shadow":"0px 10px 15px 0px rgb(0 0 0 / 60%)","border-radius":"0"},attrs:{"width":"615px","alt":"tulum epic nights","height":"250px"}}),_c('br'),_c('h1',{staticClass:"tulum-party-title",staticStyle:{"color":"#ffffff !important","margin-top":"20px","text-transform":"uppercase"}},[_vm._v(" TULUM PARTY ")]),_c('br'),_vm._m(0),_c('br'),_vm._m(1),_c('SmartMenu',{attrs:{"logo":this.logo}},[_c('p',[_vm._v("Feeling Lost?"),_c('br'),_vm._v("We are here to help")]),_c('br')])],1)]),_c('aside',{staticClass:"card col-4 col-xl-4 d-none d-xl-inline"},[_c('SmartMenu',{attrs:{"logo":this.logo}},[_c('p',[_vm._v("Feeling Lost?"),_c('br'),_vm._v("We are here to help")]),_c('br')])],1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',[_vm._v("Search Engine: Find Parties, Events, & Clubs. "),_c('br'),_c('strong',[_vm._v("What are you looking for?")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"width":"80%","margin":"0 auto","height":"140px"},attrs:{"id":"cse"}},[_c('div',{staticClass:"gcse-search"})])}]

export { render, staticRenderFns }