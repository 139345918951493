<template>
  <div class="content">
    <article
      class="row">
      <section class="col-lg-8 col-sm-12">

        <div
          class="centered text-center"
          style="width: 100%; "
          :style="{
              background: `linear-gradient(${this.secondaryColor}, black)`,
            }"
        >
          <img
            v-lazy="
                mainImage +
                '?w=315&h=200&fit=clamp&auto=format'
              "
            style="
                margin-top: -10px;
                z-index: 12;
                box-shadow: 0px 10px 15px 0px rgb(0 0 0 / 60%);
                border-radius: 0;
              "
            width="315px"
            height="200px"
            alt="tulum epic nights"
            class="d-inline-xs d-sm-none"
          />

          <img
            v-lazy="
                 mainImage +
                '?w=615&h=250&fit=clamp&auto=format'
              "
            style="
                margin-top: -10px;
                z-index: 12;
                box-shadow: 0px 10px 15px 0px rgb(0 0 0 / 60%);
                border-radius: 0;
              "
            width="615px"
            class="d-none d-sm-inline"
            alt="tulum epic nights"
            height="250px"
          />
          <br/>

          <h1
            class="tulum-party-title"
            style="
                color: #ffffff !important;
                margin-top: 20px;
                text-transform: uppercase;
              "
          >
            TULUM PARTY
          </h1>

          <br>
          <p>Search Engine: Find Parties, Events, & Clubs.
            <br>
            <strong>What are you looking for?</strong></p>
          <br>
          <div id="cse" style="width: 80%; margin: 0 auto;height: 140px">
            <div class="gcse-search"></div>
          </div>
          <SmartMenu :logo="this.logo">
            <p>Feeling Lost?<br>We are here to help</p>
            <br>
          </SmartMenu>
        </div>
      </section>
      <aside class="card col-4 col-xl-4 d-none d-xl-inline">
        <SmartMenu :logo="this.logo">
          <p>Feeling Lost?<br>We are here to help</p>
          <br>

        </SmartMenu>
      </aside>
    </article>
  </div>

</template>

<script>

import SmartMenu from "@/components/SmartMenu/SmartMenu";

const RsvpViaDialog = () =>
  import(
    /* webpackChunkName: "RsvpDialogs" */ '../../components/Reservations/RsvpViaDialog'
    );

export default {
  name: 'Search',
  props: ['slug'],
  metaInfo() {

    return {
      title: `Tulum Party - Site Search`,
      meta: [
        {
          name: 'description',
          content: `Tulum Party - Search for Parties, Events, Clubs & More`
        },
        {
          name: 'keywords',
          content: `site search, events, clubs`
        },
        {
          property: 'og:title',
          content: `Site Search`,
        },
        {
          property: 'og:url',
          content: `https://tulum.party/search`,
        },
        {
          property: 'og:description',
          content: `Search For Parties & Events`,
        },
        {property: 'og:type', content: 'article'},
        {
          property: 'og:image',
          content: 'https://imgix.cosmicjs.com/57a1ab60-a461-11ee-b417-db331415685f-tpicon.png'
        },
      ],
    };
  },
  data: () => {
    return {
      mainImage: 'https://imgix.cosmicjs.com/e02422c0-54f5-11ef-b1ea-f56c65dfade9-eye-lex.jpg',
      secondaryColor: 'silver',
      logo: 'https://imgix.cosmicjs.com/e02422c0-54f5-11ef-b1ea-f56c65dfade9-eye-lex.jpg'

    };
  },
  methods: {},
  components: {SmartMenu},
  mounted() {
    let divScripts = document.getElementsByTagName('head')[0];
    let newScript = document.createElement('script');
    newScript.src = 'https://cse.google.com/cse.js?cx=57b102e1ce2dc4486';
    divScripts.appendChild(newScript);
  },
};
</script>
<style>
tr {
  background-color: #a5a5a5;
  font-size: 0.8rem;
}
</style>

